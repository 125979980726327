import React from "react"
import { Link } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import CtaBoxes from "../components/promotions/ctaBoxes"
import { SectionHeading } from "../components/headings"

const ErrorPage = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <Seo
        title="Ups, si se izgubil?"
        lang="sl"
        description="Lightroom Mobile Presets - Urejaj slike z enim klikom - Top kvaliteta in doživljenski dostop. 24/7 podpora."
      />
      <section className="max-w-screen-xl mx-auto px-4 pt-10 sm:pt-16">
        <SectionHeading title="heading11" />
        <p className="text-center">
          Ups, ta stran očitno ne obstaja. <Link to="/">Klikni tukaj</Link>, da
          greš nazaj domov.{" "}
          <span role="img" aria-label="House icon">
            🏡
          </span>
        </p>
      </section>
      {/* <section className="max-w-screen-xl mx-auto px-4 pt-6 pb-10 sm:pb-16">
        <CtaBoxes borderTop={false} />
      </section> */}
    </Layout>
  )
}

export default ErrorPage
